import React from "react";
import { smile } from "../../utils/images";
import { useNavigate, useParams } from "react-router-dom";

export default function Usernotfound() {
  const { name, campus } = useParams()
  const navigate = useNavigate();
  function HandleClickEvent() {
    navigate(`/${campus}`);
  }


  return (
    <div className="smile">
      <div>
        <img src={smile} alt="" />
        <h1>
          UH OH,WE DONT HAVE ANYONE<br></br>IN OUR RECORD BY THAT NAME
        </h1>
        <div className="back-btn">
          <button onClick={HandleClickEvent}>GO BACK</button>
        </div>
      </div>
    </div>
  );
}
