import React from "react";
import { notfound } from "../../utils/images";
export default function BackendError() {
  return (
    <div className="page-not-found">
      <div>
        <img src={notfound} alt="" />
        <h1>
          ERROR<br></br> CONNECTING TO <br /> BACKEND
        </h1>
      </div>
    </div>
  );
}
