import React from "react";
import { detailstudent } from "../../utils/images";
import { social3, social4 } from "../../utils/images";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import AppLayout from "../../shared/layouts";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Select from "react-select";
import { TiSocialInstagram } from "react-icons/ti";
import axios from "axios";
import { base_url } from "../../config/config";
export default function Studentdetails() {
  const { campus } = useParams()
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cell, setCell] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [parents, setParents] = useState("");
  const [parentsEmail, setParentsEmail] = useState("");
  const [gender, setGender] = useState(null);
  const [birthday, setBirthday] = useState("");
  const [grade, setGrade] = useState(null);
  const [school, setSchool] = useState("");
  const [paramsCampus, setParamsCampus] = useState(null);
  const [mainCampus, setMainCampus] = useState(null);
  const [city, setCity] = useState("");
  const [zip, setZip] = useState("");
  const [parentNumber, setParentNumber] = useState("");
  const [instagram, setInstagram] = useState("");
  const [tiktok, setTiktok] = useState("");
  const navigate = useNavigate();
  const options = [
    { value: "6th Grade", label: "6th Grade" },
    { value: "7th Grade", label: "7th Grade" },
    { value: "8th Grade", label: "8th Grade" },
    { value: "9th Grade", label: "9th Grade" },
    { value: "10th Grade", label: "10th Grade" },
    { value: "11th Grade", label: "11th Grade" },
    { value: "12th Grade", label: "12th Grade" },
    { value: "Leader", label: "Leader" },
  ];
  const optionsCampus = [
    { value: "Oconee", label: "Oconee" },
    { value: "Loganville", label: "Loganville" },
    { value: "Monroe", label: "Monroe" },
  ];
  const optionsGender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ]
  const handleChange = (selectedOption) => {
    setGrade(selectedOption);
  };
  const handleChangeCampus = (selectedOption) => {
    setMainCampus(selectedOption);
  };
  const handleChangeGender = (selectedOption) => {
    setGender(selectedOption);
  };

  useEffect(() => {
    if (campus === 'loganville') {
      setParamsCampus('Loganville')
    } else if (campus === 'oconee') {
      setParamsCampus('Oconee')
    } else if (campus === 'monroe') {
      setParamsCampus('Monroe')
    } else {
      setParamsCampus('Loganville')
    }
  }, [])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "black",
      background: state.isSelected ? "#fff" : "#fff ",
      padding: 10,
    }),
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (gender === null) {
      alert("Please fill out the gender field!")
    } else if (grade === null) {
      alert("Please fill out the grade field!")
    } else if (campus === null) {
      alert("Please fill out the Main campus field!")
    } else if (cell === "") {
      alert("Please fill out the cell number field!")
    } else {
      try {
        let config = {
          headers: {
            'key': process.env.REACT_APP_BACKEND_KEY,
            'Content-Type': 'application/json'
          }
        };
      
        let res = await axios.post(`${base_url}/checkin/newstudent`, {
          firstName: firstName,
            lastName: lastName,
            cell: cell,
            email: email,
            address: address,
            state: state,
            parents: parents,
            parentsEmail: parentsEmail,
            gender: gender.value,
            birthday: birthday,
            grade: grade.value,
            school: school,
            mainCampus: mainCampus.value,
            city: city,
            zip: zip,
            parentNumber: parentNumber,
            instagram: instagram,
            tiktok: tiktok,
            campus: paramsCampus
        }, config);
      
        if (res.status === 200) {
          if (campus === 'Loganville' || campus === 'loganville') {
            navigate(`/${campus}/${res.data.Id}/updateprofile`);
          } else {
            navigate(`/${campus}/allcheckedin`);
          }
        } else {
          navigate("/500");
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <>
      <div data-aos="zoom-in">
        <AppLayout logo={detailstudent}>
          <h1 className="about-text">FIRST TIME? TELL US MORE ABOUT YOU!</h1>
          <form onSubmit={handleSubmit}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div class="form-group row">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>
                        FIRST NAME: <strong className="red-color">*</strong>
                      </p>
                    </label>
                    <div class="col-sm-7">
                      <input required type="text" class="form-control" onChange={(e) => setFirstName(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>
                        LAST NAME: <strong className="red-color">*</strong>{" "}
                      </p>
                    </label>
                    <div class="col-sm-7">
                      <input required type="text" class="form-control"
                      onChange={(e) => setLastName(e.target.value)} />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>CELL#: <strong className="red-color">*</strong></p>
                    </label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" 
                      onChange={(e) => setCell(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>E-MAIL:</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="email" class="form-control" 
                      onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>ADDRESS:</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" 
                      onChange={(e) => setAddress(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>STATE:</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" 
                      onChange={(e) => setState(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>PARENT(S):</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" 
                      onChange={(e) => setParents(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>PARENT E-MAIL:</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" 
                      onChange={(e) => setParentsEmail(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>
                        GENDER: <strong className="red-color">*</strong>
                      </p>
                    </label>
                    <div class="col-sm-7">
                      <Select
                        required
                        styles={customStyles}
                        options={optionsGender}
                        value={gender}
                        onChange={handleChangeGender}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>
                        BIRTHDAY: <strong className="red-color">*</strong>{" "}
                      </p>
                    </label>
                    <div class="col-sm-7">
                      <input required type="date" class="form-control" 
                      onChange={(e) => setBirthday(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>
                        GRADE: <strong className="red-color">*</strong>
                      </p>
                    </label>
                    <div class="col-sm-7">
                      <Select
                        required
                        styles={customStyles}
                        options={options}
                        value={grade}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>SCHOOL:</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" 
                      onChange={(e) => setSchool(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>
                        CAMPUS: <strong className="red-color">*</strong>
                      </p>
                    </label>
                    <div class="col-sm-7">
                      <Select
                        required
                        styles={customStyles}
                        options={optionsCampus}
                        value={mainCampus}
                        onChange={handleChangeCampus}
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>CITY:</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" 
                      onChange={(e) => setCity(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>ZIP:</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="number" class="form-control" 
                      onChange={(e) => setZip(e.target.value)}/>
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-sm-5 col-form-label text-end">
                      <p>PARENT #:</p>
                    </label>
                    <div class="col-sm-7">
                      <input type="number" class="form-control" 
                      onChange={(e) => setParentNumber(e.target.value)}/>
                    </div>
                  </div>
                  {/* <div class="form-group row mt-1">
                    <div className="col-sm-5 d-flex align-items-center">
                      <img src={social3} alt="" className="social-img" />
                    </div>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" />
                    </div>
                  </div> */}
                  <div class="form-group row mt-1">
                    <div className="col-sm-5 d-flex align-items-center">
                      <img src={social4} alt="" className="social-img" />
                    </div>
                    <div class="col-sm-7">
                      <input type="text" class="form-control" 
                      onChange={(e) => setTiktok(e.target.value)}/>
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <div className="col-sm-5 d-flex align-items-center">
                      <img src={social3} alt="" className="social-img" />
                    </div>
                    <div class="col-sm-7">
                      <input type="text" class="form-control"
                      onChange={(e) => setInstagram(e.target.value)} />
                    </div>
                  </div>
                </div>
                
                <div className="submit-btn-main">
                  <div className="text-center mt-2">
                    <button className="std-continue" type="submit">
                      CONTINUE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </AppLayout>
      </div>
    </>
  );
}
