import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "gscheckin-ae1db.firebaseapp.com",
  projectId: "gscheckin-ae1db",
  storageBucket: "gscheckin-ae1db.appspot.com",
  messagingSenderId: "1037714510233",
  appId: "1:1037714510233:web:b27bc5d341fde4022e4308"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)