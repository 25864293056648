import React from "react";
import { notfound } from "../../utils/images";
export default function Error500() {
  return (
    <div className="page-not-found">
      <div>
        <img src={notfound} alt="" />
        <h1>
          EVENT<br></br> NOT FOUND
        </h1>
      </div>
    </div>
  );
}
