import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AppLayout from "../../shared/layouts";
import { appLogo } from "../../utils/images";
import { user, users, tick } from "../../utils/images";
import { FaRegUser, FaCheck } from "react-icons/fa";
import { useEffect } from "react";
import { useState } from "react";
import { MdGroups } from "react-icons/md";
import Aos from "aos";
import "aos/dist/aos.css";
import { useParams } from "react-router-dom";
import { base_url } from "../../config/config";
import toast, { Toaster } from 'react-hot-toast';
export default function Alluser(props) {
  const { name, campus } = useParams()
  console.log(name)
  const navigate = useNavigate();

  const [students, setStudents] = useState([{
    name: 'Loading...',
  }])
  const [checkIn, setCheckIn] = useState(0)
  const [active, setActive] = useState(-1);
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });

    axios.get(`${base_url}/checkin/students/${name}`, {
      headers: {
        'key': process.env.REACT_APP_BACKEND_KEY
      }
    })
      .then(res => {
        if (res.data.length === 0) {
          navigate(`/${campus}/usernotfound`)
        } else {
          setStudents(res.data)
        }
      })
      .catch(err => {
        toast.error('Error Connecting to Backend || EC001', {
          duration: 100000,
          position: 'top-center',
        })
        console.log(err)
      })
  }, []);

  useEffect(() => {
    if (students[checkIn].name !== 'Loading...') {
      var config = {
        method: 'post',
        url: `${base_url}/checkin/student/${students[checkIn].Id}/${campus}`,
        headers: { 
          'key': process.env.REACT_APP_BACKEND_KEY
        }
      };
      axios(config)
        .then(function (res) {
          if (res.data.info === 'MissingPFP/SuccessfulCheckin') {
            navigate(`/${campus}/${students[checkIn].Id}/updateprofile`)
          } else if (res.data.info === 'alrcheckedin') {
            navigate(`/${campus}/alrcheckedin`)
          } else if (res.data.info === 'Success') {
            navigate(`/${campus}/checkedin`)
          } else if (res.data.info === 'SUCCESS') {
            navigate(`/${campus}/checkedin`)
          } 
        })
      .catch(function (error) {
        toast.error('Error Connecting to Backend || EC001', {
          duration: 10000,
          position: 'top-center',
        })
        console.log(error);
      });
    } 
  }, [checkIn]);

  return (
    <div data-aos="zoom-in">
      <Toaster />
      <AppLayout>
        <div className="all-users">
          <div>
            <div className="main-scroll">
              {students.map((student, i) => (
                <div
                  className={`main-div ${
                    active == i ? "selected-main-div" : ""
                  }`}
                  onClick={() => setActive(i)}
                  key={i}
                >
                  <div className="align" key={student.Id}>
                    <FaRegUser className="usersingle" />
                    <p>{student.full_name}</p>
                  </div>
                  <div className="align" key={student.Id}>
                    <MdGroups className="usersingle" />
                    <p className="ms-1">{student.mainSmallGroup}</p>
                  </div>
                  <div className="" key={student.Id}>
                    <div className="tick-mark">
                      <FaCheck />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button value={active} className="checked" onClick={e => setCheckIn(e.target.value)}>CHECK IN</button>
            </div>
          </div>
        </div>
      </AppLayout>
    </div>
  );
}
