import CheckInType from "./views/auth/checktypein";
import Name from "./views/auth/name";
import { Routes, Switch, Route, Link } from "react-router-dom";
import Studentdetails from "./views/auth/studentdetails";
import Checkedin from "./views/auth/checkedin";
import UserNotFound from "./views/auth/usernotfound";
import { useState } from "react";
import Alluser from "./views/auth/alluser";
import Checkedintwo from "./views/auth/checkedintwo";
import PageNotFound from "./views/auth/pagenotfound";
import Error500 from "./views/auth/Error500";
import CampusSelect from "./views/auth/selectcampus";
import AlreadyCheckedIn from "./views/auth/alrCheckedIn";
import BackendError from "./views/auth/ServerError";
import * as Sentry from "@sentry/react";
import Camera from "./views/auth/Camera"
 
function App() {
  return (
    <Routes>
      <Route path="/" element={<CampusSelect />} />
      <Route path="/:campus" element={<CheckInType />} />
      <Route path="/:campus/name" element={<Name />} />
      <Route path="/:campus/studentdetails" element={<Studentdetails />} />
      <Route path="/:campus/allcheckedin" element={<Checkedin />} />
      <Route path="/:campus/allusers/:name" element={<Alluser />} />
      <Route path="/:campus/checkedin" element={<Checkedintwo />} />
      <Route path="/:campus/usernotfound" element={<UserNotFound />} />
      <Route path="/:campus/alrcheckedin" element={<AlreadyCheckedIn />} />
      <Route path="/:campus/:id/updateprofile" element={<Camera />} />
      <Route path="/500" element={<Error500 />} />
      <Route path="/401" element={<BackendError />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Sentry.withProfiler(App);
