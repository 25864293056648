import AppLayout from "../../shared/layouts";
import { Link, useNavigate, useParams } from "react-router-dom";
import { appLogo } from "../../utils/images";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { smile } from "../../utils/images";
import axios from "axios";
import { base_url } from "../../config/config";
export default function CheckInType() {
  const navigate = useNavigate()
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 800,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  const { campus } = useParams();

  const [status, setStatus] = useState(0)
  const [ready, setReady] = useState(false)

  async function checkStatus() {
    var config = {
      method: 'get',
      url: `${base_url}/checkin/status/${campus}`,
      headers: { 
        'key': process.env.REACT_APP_BACKEND_KEY,
        'Access-Control-Allow-Origin': true,
        'Content-Type': 'application/json'
      }
    };

    const res = await axios(config)
    .catch(function (error) {
      navigate(`/401`)
      console.log(error);
    })
    setStatus(res.status)
    setReady(true)
  }
  useEffect(() => {
    checkStatus()
  }, [])

  if (ready) {
    if (status === 200) {
      return (
        <div data-aos="zoom-in">
                <AppLayout>
                  <h1 className="text-center welcome">WELCOME IN!</h1>
                  <div className="continue-btn">
                    <Link to={`name`}>
                      <button className="returning-std">RETURNING STUDENT</button>
                    </Link>
                    <Link to={`studentdetails`}>
                      <button className="first-time">
                        FIRST <br /> TIME?
                      </button>
                    </Link>
                  </div>
                </AppLayout>
        </div>
      )
    } else {
      return (
        <div className="checkin-closed">
            <div>
              <img src={smile} alt="" />
              <h1>
                CHECK-IN<br />CLOSED
              </h1>
            </div>
          </div>
      )
    }
  
  }

}
