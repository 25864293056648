import React, { useState, useRef } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import Webcam from 'react-webcam';
import Avatar from '../../assets/images/avatar.webp'
import {storage} from "../../config/firebase"
import { getDownloadURL, ref, uploadBytes } from '@firebase/storage';
import {Buffer} from 'buffer';
import { base_url, key } from '../../config/config';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
const videoConstraints = {
    width: 400,
    height: 400,
    facingMode: 'user',
};

const Profile = () => {
    const { campus, id } = useParams()
    const [picture, setPicture] = useState('');
    const webcamRef = useRef(null);
    const navigate = useNavigate()

    const capture = () => {
        const pictureSrc = webcamRef.current.getScreenshot();
        setPicture(pictureSrc);
    };

    const retakePhoto = () => {
        setPicture('');
    };

    const updatePfp = (picUrl, id) => {
        console.log(picUrl, id)
        var config = {
            method: 'post',
            url: `${base_url}/checkin/student/addpfp`,
            headers: { 
              'key': process.env.REACT_APP_BACKEND_KEY,
              'Content-Type': 'application/json'
            },
            data: {
                "student": {
                    "id": id,
                    "pfpUrl": picUrl
                }
            }
          };
          console.log(config)
          axios(config)
            .then(function (res) {
              if (res.data.info === 'Success') {
                navigate(`/${campus}/checkedin`)
              } else {
                navigate(`/401`)
              }
            })
          .catch(function (error) {
            toast.error('Error Connecting to Backend || EC001', {
              duration: 10000,
              position: 'top-center',
            })
        })
    }

    const uploadPhoto = () => {
        toast.loading('Uploading...')
        const base64Image = picture.split(';base64,').pop();
        const imageBuffer = Buffer.from(base64Image, 'base64')

        const imageRef = ref(storage, `images/profilepicture/${id}.jpg`)
        uploadBytes(imageRef, imageBuffer).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) =>
                updatePfp(url, id)
            )
        })
    }
    
    const noThanksPFP = () => {
        var config = {
            method: 'post',
            url: `${base_url}/checkin/student/addpfp`,
            headers: { 
              'key': process.env.REACT_APP_BACKEND_KEY,
              'Content-Type': 'application/json'
            },
            data: {
                "student": {
                    "id": id,
                    "pfpUrl": 'OPT_OUT'
                }
            }
          };
          axios(config)
            .then(function (res) {
              if (res.data.info === 'Success') {
                navigate(`/${campus}/checkedin`)
              } else {
                navigate(`/401`)
              }
            })
          .catch(function (error) {
            toast.error('Error Connecting to Backend || EC001', {
              duration: 10000,
              position: 'top-center',
            })
        })
    }

    return (
        <div >
            <Toaster />
            <div className='cam-head'>
                <img src={Avatar} alt="" />
                <h1>
                    TIME TO UPDATE YOUR PROFILE PICTURE
                </h1>
            </div>
            <div className='cam-body'>
                {picture ? (
                    <img src={picture} alt="Captured"
                        style={{ borderRadius: '500px', border: "8px solid white" }}
                    />
                ) : (
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        style={{ borderRadius: '500px', border: "8px solid white" }}
                        className="web-cam"
                    />
                )}
            </div>
            <div>
                {picture ? (
                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: "26px", paddingBottom: "5px" }}>
                        <button onClick={retakePhoto} className="py-2 rounded-lg" style={{ marginBottom: '10px', backgroundColor: '#FF8989', paddingLeft: '43px', paddingRight: '43px', fontWeight: 600, borderRadius: '10px' }}>
                            Retake
                        </button>
                        <button onClick={uploadPhoto} className="py-2 rounded-lg" style={{ backgroundColor: '#A1F9CE', paddingLeft: '41px', paddingRight: '41px', fontWeight: 600, borderRadius: '10px' }}>
                            Approve
                        </button>

                    </div>

                ) : (
                    <div className='d-flex flex-column justify-content-center align-items-center' style={{ paddingTop: '26px', paddingBottom: '10px' }}>
                        <button onClick={capture} style={{ padding: "8px 34px", fontWeight: '600', borderRadius: '10px' }}>
                            Capture
                        </button>
                        <br />
                        <button onClick={noThanksPFP} style={{ padding: "8px 34px", fontWeight: '600', borderRadius: '10px' }}>
                            No Thanks
                        </button>
                    </div>

                )}
            </div>
        </div >
    );
};

export default Profile;
